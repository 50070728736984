.container.contact__container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ===================== FROM ===================== */

form {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width: 600px) {
    form {
        width: 90%;
    }
}