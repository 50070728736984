.portofolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portofolio__items {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portofolio__items:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portofolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portofolio__items h3 {
    margin: 1.2rem 0 2rem;
    height: 80px;
}

.portofolio__items-image {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portofolio__items-image img {
    border-radius: 1rem;
}

.portofolio__item-cta {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
/* ===================== MEDIA QUERIES (MEDUIM DEVICES) ===================== */
@media screen and (max-width: 1024px) {
    .portofolio__container{
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
    }
}

/* ===================== MEDIA QUERIES (SMALL DEVICES) ===================== */
@media screen and (max-width: 600px) {
   .portofolio__container{
    grid-template-columns: 1fr;
    gap: 1rem;
   }
}